/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from '@lce/i18n';
import _some from 'lodash/some';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import { Flex } from '@lce/slice_v2';

import {
  FundraiserRelationship, useFetchChairpersonDashboardStatistics,
} from 'features/fundraiser';
import { FundraiserStartCaesar, SellerProgress } from 'ui/fundraiser';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { DashboardHeader } from 'ui/fundraiser/DashboardHeader';
import ChairpersonSteps from 'ui/fundraiser/ChairpersonSteps/ChairpersonSteps';
import { Graphs, GraphsSkeleton } from 'ui/Graphs';
import { useFetchGroup } from 'features/fundraiser/hooks/useFetchGroup';
import { ProfitCheckAddressModal } from 'ui/fundraiser/ProfitCheckAddressModal';
import { ProvideAddressBanner } from 'ui/fundraiser/ProvideAddressBanner';
import { FundraiserProfitCheckAddress, FundraiserStatus, FundraiserType } from 'features/fundraiser/types/fundraiser';
import { hasIncompleteProfitCheckAddress } from 'features/fundraiser/utils/HasIncompleteProfitCheckAddress';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';
import { useStatefulFetchFundraiserById } from 'ui/hooks';
import { BackToDashboard } from 'ui/components';

interface MatchParams {
  fundraiserId: string;
}

export type FundraiserDetailPageProps = RouteComponentProps<MatchParams>
export const dataTestIdFundraiserDetail = 'fundraiser-detail';

const FundraiserDetail: React.FC<FundraiserDetailPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const {
    data: fundraiser, isLoading, isError, error,
  } = useStatefulFetchFundraiserById(match.params.fundraiserId);
  const { data: chairpersonStats, isLoading: isChairpersonStatsLoading } =
    useFetchChairpersonDashboardStatistics(Number(match.params.fundraiserId));
  const { data: groupDetail } = useFetchGroup(fundraiser?.GroupId.toString() || '');

  const [ isAddressModalOpen, setIsAddressModalOpen ] = React.useState<boolean>(false);
  const [ fundraiserProfitCheckData, setFundraiserProfitCheckData ] =
    React.useState<FundraiserProfitCheckAddress | null>(null);

  const relationshipsThatCanViewPage = [
    FundraiserRelationship.CHAIRPERSON,
    FundraiserRelationship.SUPERADMIN,
    FundraiserRelationship.SALESAGENT,
    FundraiserRelationship.ISC,
  ];

  const userCanViewPage = _some(relationshipsThatCanViewPage,
    relationship => _includes(fundraiser?.Relationships, relationship));

  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const isFundraiserCancelled = !_isNil(fundraiser?.CancellationDate);
  const isFundraiserUpcomingAndNotCancelled = isFundraiserUpcoming && !isFundraiserCancelled;
  const showGraphs = !(isFundraiserCancelled || isFundraiserUpcoming);
  const isFundraiserExpiredOrCancelled = isFundraiserCancelled || fundraiser?.IsExpired;

  const isMissingProfitCheckAddress = hasIncompleteProfitCheckAddress(fundraiser, groupDetail);
  const showProvideAddressBanner = isFundraiserExpiredOrCancelled && isMissingProfitCheckAddress;

  const formattedStartDate = fundraiser && format(new Date(fundraiser?.StartDate), 'MM/dd/yyyy');
  const startingSoonMessage = t('fundraiser.StartingSoon',
    { context: fundraiser?.FundraiserStatus === FundraiserStatus.Pending && 'pending', formattedStartDate });

  const handleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  useEffect(() => {
    if (fundraiser && groupDetail) {
      setFundraiserProfitCheckData({
        fundraiserId: fundraiser?.Id || 0,
        profitCheckPayableTo: fundraiser?.ProfitCheckPayableTo || '',
        groupAddress: groupDetail?.GroupAddress?.Address1 || '',
        groupCity: groupDetail?.GroupAddress?.City || '',
        groupState: groupDetail?.GroupAddress?.StateProvinceIso || '',
        groupZipCode: groupDetail?.GroupAddress?.ZipPostalCode || '',
      });
    }
  }, [ fundraiser, groupDetail ]);

  if (!isLoading && !userCanViewPage) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  if (isError && error?.Status === 404) {
    return <Redirect to="/404" />;
  }

  if (isError && error?.Status === 403) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />

      <Flex data-testid={ dataTestIdFundraiserDetail } sx={ { flexDirection: 'column', gap: [ '16px', '32px' ] } }>
        <BackToDashboard pathname="/dashboard/fundraisers" text={ t('dashboard.BackToAllFundraisers') } />

        {showProvideAddressBanner && (
          <ProvideAddressBanner onClick={ handleAddressModal } />
        )}

        <ProfitCheckAddressModal
          fundraiserHasExpired={ !!isFundraiserExpiredOrCancelled }
          fundraiserId={ fundraiser?.Id || 0 }
          isMissingProfitCheckAddress={ isMissingProfitCheckAddress }
          isOpen={ isAddressModalOpen }
          onClose={ handleAddressModal }
          profitCheckData={ fundraiserProfitCheckData }
        />

        {fundraiser && fundraiser.FundraiserType === FundraiserType.Brochure &&
          <SellerProgress actual={ fundraiser.TotalRaised || 0 } goal={ fundraiser.DollarGoal } />}

        {fundraiser && <DashboardHeader description={ fundraiser.CustomMessage } fundraiser={ fundraiser } />}

        {isFundraiserUpcomingAndNotCancelled && <FundraiserStartCaesar message={ startingSoonMessage } /> }

        {showGraphs && (
          isChairpersonStatsLoading
            ? <GraphsSkeleton isDashboard={ true } />
            : <Graphs graphStats={ chairpersonStats || [] } isDashboard={ true } />
        )}

        {fundraiser && (
          <ChairpersonSteps
            fundraiser={ fundraiser }
            handleAddressModal={ handleAddressModal }
            isMissingProfitCheckAddress={ isMissingProfitCheckAddress }
          />
        )}
      </Flex>
    </>
  );
};

export default FundraiserDetail;
