export default {
  accountOptionLinksText: {
    fontSize: '16px',
    fontWeight: 800,
    textTransform: 'initial',
    color: 'black',
    '&:hover': {
      color: 'primaryOrange',
    },
  },
  accountSettings: {
    disclaimer: {
      fontFamily: 'Lato',
      fontSize: '12px',
      color: 'primaryGrayDark',
    },
    title: {
      variant: 'text.product.title',
      fontSize: '34px',
    },
  },
  cartListItem: {
    price: {
      fontSize: '20px',
      fontWeight: 700,
      color: 'black',
      flex: 1,
      textAlign: [ 'left', 'right' ],
    },
    name: {
      fontSize: '20px',
      fontFamily: 'Lato',
      fontWeight: 600,
    },
    description: {
      fontFamily: 'Lato',
      fontSize: '14px',
    },
  },
  emailNotificationForm: {
    label: {
      fontSize: '12px',
      color: '#868686',
      lineHeight: 1,
      fontWeight: 'normal',
      marginTop: '16px',
    },
    formLabel: {
      fontSize: '14px',
      fontFamily: 'roboto',
    },
  },
  heading: {
    fontFamily: 'mrEaves',
  },
  header: {
    title: {
      fontFamily: 'mrEaves',
      fontWeight: '900',
      fontSize: [ '24px', '24px', '36px' ],
      lineHeight: [ 1 ],
      color: 'black',
      large: {
        fontFamily: 'mrEaves',
        fontSize: [ '34px', '34px', '64px' ],
        lineHeight: 1,
        color: 'primaryOrange',
        fontWeight: 700,
      },
    },
    sub: {
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 900,
      mr: '10px',
    },
    subUpper: {
      fontFamily: 'mrEaves',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    form: {
      fontFamily: 'Lato',
      color: 'primaryGrayDark',
      fontSize: '18px',
      fontWeight: 600,
    },
    instructions: {
      fontFamily: 'Roboto',
      fontSize: '14px',
    },
  },
  h2: {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: 'primaryGrayDark',
    fontSize: '20px',
  },
  h5: {
    fontFamily: 'Lato',
    fontWeight: 700,
    color: 'black',
    fontSize: '16px',
    lineHeight: '19px',
  },
  footer: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '14px',
  },
  fundraiserListItem: {
    name: {
      variant: 'text.cartListItem.name',
    },
    description: {
      font: 'Lato',
      color: 'primaryGrayDark',
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  fundraiserOptions: {
    header: {
      fontFamily: 'lato',
      fontWeight: 'bold',
      fontSize: '16px',
    },
    subHeader: {
      fontFamily: 'lato',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    label: {
      ml: '24px',
      fontFamily: 'roboto',
      fontSize: '14px',
    },
  },
  fundraiserDetail: {
    sellerStatusHeader: {
      variant: 'text.h5',
      fontSize: '18px',
    },
    manageFundraisers: {
      fontFamily: 'Lato',
      color: 'primaryOrange',
      fontSize: '16px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    buyerListEmptyStateHeader: {
      variant: 'text.header.title',
      fontSize: '22px !important',
    },
  },
  helper: {
    lineHeight: 1,
  },
  login: {
    message: {
      margin: '16px 0',
      lineHeight: 1,
      fontSize: '14px',
    },
  },
  productListItem: {
    name: {
      fontSize: [ '16px', '20px' ],
      fontWeight: 700,
      lineHeight: 1.2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      '.product-image:onhover + .product-name': {
        color: 'primaryOrange',
      },
    },
    price: {
      WebkitLineClamp: '3',
      maxHeight: '3.6em',
      variant: 'text.productListItem.name',
      ':hover': {
        color: 'black',
      },
    },
    description: {
      variant: 'text.productListItem.name',
      fontSize: [ '15px', '16px' ],
      lineHeight: 1.2,
      color: 'primaryGrayDark',
      ':hover': {
        color: 'primaryGrayDark',
      },
    },
  },
  orderHistory: {
    label: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      color: 'primaryGrayDark',
    },
  },
  product: {
    title: {
      fontWeight: 600,
      font: 'Lato',
      fontSize: '36px',
    },
    price: {
      variant: 'text.product.title',
      fontSize: '20px',
    },
    description: {
      fontSize: '16px',
    },
    shortDescription: {
      variant: 'text.product.price',
    },
  },
  quote: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: [ '32px' ],
    fontSize: [ '28px' ],
    color: 'primaryGrayDark',
    author: {
      fontFamily: 'mrEaves',
      fontWeight: 'bold',
      fontSize: '18px',
      color: 'primaryGrayDark',
      textTransform: 'uppercase',
    },
  },
  sellerInvite: {
    description: {
      variant: 'text.header.subUpper',
      color: 'primaryOrange',
      textAlign: 'center',
      lineHeight: '20px',
      mt: '8px',
      mb: '10px',
    },
    message: {
      fontFamily: 'Lato',
      fontSize: [ '12px', '16px' ],
      lineHeight: [ '15px', '19px' ],
    },
    info: {
      fontFamily: 'Lato',
      fontSize: '12px',
      lineHeight: '12px',
      color: 'primaryGrayDark',
      whiteSpace: 'pre-line',
    },
  },
  tabbedCard: {
    sectionHeader: {
      fontWeight: 900,
      fontFamily: 'mrEaves',
      fontSize: '22px',
    },
  },
  toast: {
    content: {
      color: 'black',
      fontFamily: 'Lato',
    },
  },
  errorMessage: {
    color: 'red',
    paddingBottom: [ '8px', '4px' ],
    fontSize: 'smaller',
  },
  orderReview: {
    formLabel: {
      lineHeight: '1.8',
      fontSize: '16px',
      fontFamily: 'roboto',
    },
  },
  warning: {
    fontFamily: 'Lato',
    fontSize: '14px',
    color: 'red',
    fontWeight: 'bold',
  },
  fundraiserTracker: {
    name: {
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 900,
    },
    description: {
      fontFamily: 'Lato',
      fontSize: [ '14px', '16px' ],
      lineHeight: 'normal',
      whiteSpace: 'pre-line',
    },
    miniDetails: {
      fontFamily: 'Lato',
      fontSize: '12px',
      lineHeight: 1,
      color: 'primaryGrayDark',
      whiteSpace: 'pre-line',
    },
  },
  stats: {
    progressBar: {
      value: {
        fontFamily: 'Lato',
        fontSize: '30px',
        fontWeight: 'bold',
      },
      label: {
        fontFamily: 'Lato',
        fontSize: '12px',
      },
    },
  },
  dashboard: {
    title: {
      color: 'black',
      fontFamily: 'roboto',
      fontSize: '32px',
      fontWeight: '900',
      lineHeight: 1,
    },
    info: {
      color: 'primaryGrayDark',
      fontFamily: 'roboto',
      fontSize: '16px',
      fontWeight: '500',
    },
    name: {
      fontFamily: 'roboto',
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '1',
    },
  },
  home: {
    heading: {
      fontSize: [ '36px', '54px', '72px' ],
      fontFamily: 'Lato',
      fontWeight: 900,
      lineHeight: '1.2',
      whiteSpace: 'pre-line',
    },
    card: {
      heading: {
        fontFamily: 'mrEaves',
        fontSize: '34px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        lineHeight: '1',
      },
      title: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: 1,
      },
      description: {
        fontFamily: 'roboto',
        fontSize: '16px',
      },
    },
    subheading: {
      fontFamily: 'Lato',
      fontSize: [ '32px', '36px' ],
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    sublabel: {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subdescription: {
      fontFamily: 'roboto',
      fontSize: '16px',
    },
    iconTile: {
      title: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 'bold',
      },
      description: {
        fontFamily: 'roboto',
        fontSize: '12px',
      },
    },
    quote: {
      fontFamily: 'roboto',
      fontSize: '18px',
    },
  },
};
