/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex, Spinner } from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FundraiserRelationship, useFetchFundraiserById, useFetchSeller, useFetchSellerDashboardStatistics,
} from 'features/fundraiser';
import { Graphs, GraphsSkeleton } from 'ui/Graphs';
import { DashboardHeader } from 'ui/fundraiser/DashboardHeader';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { SellerSteps } from 'ui/fundraiser/SellerSteps';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';
import { BackToDashboard } from 'ui/components';

export interface MatchParams {
  fundraiserId: string;
  customerId: string;
}

export type SellerDetailMode = 'preview' | 'edit';

export type ISellerDetailProps = RouteComponentProps<MatchParams>;

const SellerDetail: React.FC<ISellerDetailProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const fundraiserId = parseInt(match.params.fundraiserId);
  const customerId = parseInt(match.params.customerId);
  const { data: seller, isError: isSellerError, error: sellerError } = useFetchSeller(fundraiserId, customerId);
  const { data: fundraiser } = useFetchFundraiserById(fundraiserId);
  const { data: sellerStats, isLoading: isSellerStatsLoading } = useFetchSellerDashboardStatistics(seller?.Id || 0);
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const isFundraiserCancelled = !!fundraiser?.CancellationDate;
  const showChart = !(isFundraiserCancelled || isFundraiserUpcoming);
  const backToSellers = fundraiser && (
    fundraiser.Relationships.includes(FundraiserRelationship.CHAIRPERSON) ||
    fundraiser.Relationships.includes(FundraiserRelationship.SUPERADMIN) ||
    fundraiser.Relationships.includes(FundraiserRelationship.SALESAGENT) ||
    fundraiser.Relationships.includes(FundraiserRelationship.ISC)
  );

  if (!seller) {
    return <Spinner variant="lce" />;
  }
  if (isSellerError && sellerError && sellerError.Status === 404) {
    return <Redirect to="/404" />;
  }
  if (isSellerError && sellerError && sellerError.Status === 403) {
    return <Redirect to="/403" />;
  }

  return (
    <Box data-testid="seller-detail-page">
      <FundraiserNavigation customerId={ `${ customerId }` } fundId={ `${ fundraiserId }` } url={ match.url } />

      <Flex sx={ { flexDirection: 'column', gap: [ '16px', '32px' ] } }>
        <BackToDashboard
          pathname={ backToSellers
            ? `/dashboard/fundraisers/manage-sellers/${ fundraiserId }`
            : '/dashboard/fundraisers' }
          text={ backToSellers ? t('dashboard.BackToAllSellers') : t('dashboard.BackToFundraisers') }
        />

        { fundraiser && seller && <DashboardHeader description={ seller.Description } fundraiser={ fundraiser } seller={ seller } />}

        {showChart && sellerStats && (
          isSellerStatsLoading
            ? <GraphsSkeleton isDashboard={ true } />
            : <Graphs graphStats={ sellerStats } isDashboard={ true } testId="seller-stats" />
        )}

        { fundraiser && !fundraiser.IsExpired && !isFundraiserCancelled &&
          <SellerSteps customerId={ customerId } fundraiser={ fundraiser } sellerId={ seller.Id } supportGuid={ seller.SupportGuid } />}
      </Flex>
    </Box>
  );
};

export default SellerDetail;
