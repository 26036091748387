import React from 'react';
import { Flex } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import { Graph } from './Graph';

import { Stat } from 'features/fundraiser/types/stats';
import { formatGraphStats, FormattedStat } from 'features/fundraiser/utils/FormatGraphStats';

export interface IGraphs {
  graphStats: Stat[];
  isDashboard: boolean;
  testId?: string;
}

const Graphs: React.FC<IGraphs> = ({ graphStats, isDashboard, testId }) => {
  const [ t ] = useTranslation();
  const graphs = formatGraphStats(graphStats, isDashboard);

  const gap = isDashboard ? [ '22px', '22px', '22px', '45px' ] : [ '16px', '32px' ];
  const minWidth = isDashboard ? [ 'none', 'none', 'none', '755px' ] : [ 'none', '404px' ];
  const minWidthEven = isDashboard ? [ 'none', '332px', '332px', '355px' ] : [ 'none', '186px' ];
  const flexWrap = isDashboard ? [ 'wrap', 'wrap', 'wrap', 'nowrap' ] : [ 'wrap', 'nowrap' ];

  const evenGraphs: FormattedStat[][] = [];
  const hasEvenNumberOfGraphs = graphs.length % 2 === 0;
  if (hasEvenNumberOfGraphs) {
    for (let i = 0;i < graphs.length;i += 2) {
      evenGraphs.push([ graphs[i], graphs[i + 1] ]);
    }
  }

  return (
    <Flex
      data-testid={ testId }
      sx={ {
        flexWrap, gap, justifyContent: 'center', minWidth,
      } }
    >
      {hasEvenNumberOfGraphs
        ? _map(evenGraphs, evenGraph => (
          <Flex
            sx={ {
              flexWrap: [ 'wrap', 'nowrap' ],
              gap,
              justifyContent: 'center',
              minWidth: minWidthEven,
            } }
          >
            { _map(evenGraph, graph => (
              <Graph
                isDashboard={ isDashboard }
                key={ graph.title }
                title={ t(graph.title) }
                value={ graph.value }
              />
            ))}
          </Flex>
        ))
        : _map(graphs, graph => (
          <Graph isDashboard={ isDashboard } key={ graph.title } title={ t(graph.title) } value={ graph.value } />))}
    </Flex>
  );
};
export default Graphs;
