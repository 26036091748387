export interface Fundraiser {
  Id: number;
  Info: FundraiserInfo;
  Chairperson: ChairpersonInfo;
  SalesAgentCode?: string;
  FederalTaxId: string;
  Referral?: string;
  ProfitCheckPayableTo: string;
  FundraiserType?: FundraiserType; //TODO: make this required
}

export interface FundraiserMealDeal {
  fundraiserName: string;
  customMessage: string;
  dollarGoal: number;
  kitGoal: number;
  fundraiserType: FundraiserType;
  endDate: string;
  startDate: string;
  zipCode: string;
  franchiseeId: string;
  referralSource: string;
}

export interface FundraiserBrochure extends FundraiserCoreDetailsBR,
  ChairpersonDetailsBR, CoChairpersonDetailsBR, DeliveryDetailsBR, OrganizationDetailsBR, FundraiserBRFormStatusFlags {}

export interface DeliveryTime {
  value: number;
  label: string;
}

export interface TimeZone {
  value: number;
  label: string;
}

export interface ChairpersonDetailsBR {
  chairpersonFirstName: string;
  chairpersonLastName: string;
  chairpersonPrimaryPhoneNumber: string;
  chairpersonPrimaryPhoneNumberType: PhoneNumberTypes;
  chairpersonSecondaryPhoneNumber: string;
  chairpersonSecondaryPhoneNumberType: PhoneNumberTypes;
  chairpersonEmailAddress: string;
}

export interface CoChairpersonDetailsBR {
  coChairpersonFirstName: string;
  coChairpersonLastName: string;
  coChairpersonPrimaryPhoneNumber: string;
  coChairpersonSecondaryPhoneNumber: string;
  coChairpersonEmailAddress: string;
}

export interface DeliveryDetailsBR {
  deliveryName: string;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryState: string;
  deliveryZipCode: string;
  deliveryMainCrossStreets: string;
  deliveryRestrictions: boolean;
  deliveryComments: string;
  deliveryPhoneNumber: string;
  sameAsMailing: boolean;
  crossStreetOne?: string;
  crossStreetTwo?: string;
}

export interface OrganizationDetailsBR {
  organizationAddressId: number;
  organizationStreetAddress: string;
  organizationStreetAddress2?: string;
  organizationCity: string;
  organizationState: string;
  organizationZipCode: string;
  organizationType: string;
  organizationGroupTypeId: number;
  organizationGroupId: number;
  organizationGroupTypeName?: string;
}

export interface FundraiserCoreDetailsBR {
  Id?: number;
  name: string;
  sellerCount: number;
  profitGoal: number;
  sellerKitGoals: number;
  taxAcknowledgement: boolean;
  federalTaxId: string;
  brochuresRequestCount: number;
  postersRequestCount: number;
  startDate: Date;
  fundraiserType: FundraiserType;
  timeZone: number;
  preferredDeliveryWeek: Date;
  preferredDeliveryTime: number;
  primaryContactPhone: string;
  primaryContactPhoneType: PhoneNumberTypes;
  secondaryContactPhone: string;
  secondaryContactPhoneType: PhoneNumberTypes;
  salesAgentCode: string;
  Referral?: string;
  couponCode?: string;
}

export interface FundraiserBRFormStatusFlags {
  fundraiserDetailsFilled: boolean;
  chairpersonDetailsFilled: boolean;
  deliveryDetailsFilled: boolean;
  additionalDetailsFilled: boolean;
}

export interface FundraiserInfo {
  GroupId: number;
  GroupTypeId: number;
  GroupTypeName?: string;
  OrgName: string;
  Name: string;
  StartDate: Date;
  EndDate: Date;
  SellerKitGoal: number;
  DollarGoal: number;
  EstimatedSellers: number;
  BrochureCount?: number;
  PosterCount?: number;
  GroupAddressId: number;
  GroupAddress1: string;
  GroupAddress2: string;
  FederalTaxId: string;
  GroupCity: string;
  GroupState: string;
  GroupZip: string;
  ParentId?: number;
  SalesAgentCode: string;
  FundraiserType: FundraiserType;
}

export interface FundraiserListItem {
  Active: boolean;
  DollarGoal: number;
  TotalRaised?: number;
  EndDate: Date;
  GroupId: number;
  Id: number;
  ChairpersonCustomerId: number;
  KitGoal: number;
  MaxSellers: number;
  Name: string;
  ProfitCheckPayableTo: string;
  StartDate: Date;
  CustomMessage: string;
  FundraiserGuid: string;
  IsExpired: boolean;
  CancellationDate: Date | null;
  FundraiserImageSource: string;
  SalesCode: string;
  Relationships: FundraiserRelationship[];
  SalesAgentName: string;
  FundraiserType: FundraiserType;
  FundraiserStatus: FundraiserStatus;
  FinalOrderDetails?: FinalOrderDetails;
}

export interface FinalOrderDetails {
  Status: FinalOrderStatus;
  FinalOrderDate: Date | null;
  ViewFinalOrderUrl: string;
}

export interface ChairpersonInfo {
  Id: number;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  PhoneNumber: string;
  PrimaryPhoneNumberType?: PhoneNumberTypes;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  ZipPostalCode: string;
  CoFirstName: string;
  CoLastName: string;
  CoEmailAddress: string;
  SecondaryPhoneNumber?: string;
  SecondaryPhoneNumberType?: PhoneNumberTypes;
  CoPhoneNumber: string;
  CoSecondaryPhoneNumber?: string;
}

export interface FundraiserExtraInfo {
  TermsAndConditions: boolean;
  Referral: string;
}

export interface FundraiserSellerValidation {
  IsInvalidFundraiser: boolean;
  IsUserAlreadyRegistered: boolean;
  IsUserNotAuthorizedToRegister: boolean;
  IsUserAlreadyInFundraiser: boolean;
}

export interface FundraiserSummary {
  FundraiserId: number;
  FundraiserName: string;
  FundraiserStartDate: Date;
  FundraiserEndDate: Date;
  FundraiserSellerSummary: FundraiserSellerSummary[];
}

export interface FundraiserSearchOptions {
  selectedStartDate?: string;
  selectedEndDate?: string;
  keyword?: string;
  sort: number;
  state: FundraiserState;
  page: number;
  pageSize: number;
  canSort: boolean;
}

export interface FundraiserSellerSummary {
  SellerId: number;
  SellerName: string;
  KitGoal: number;
  KitActual: number;
  Profit: number;
  AvatarSource: string;
}

export interface CancelFundraiserRequest {
  fundraiserId: number;
  cancellationReason: string;
}

export interface FundraiserNotification {
  Name: string;
  IsSubscribed: boolean;
}
export interface FundraiserEmailPreferences {
  NotificationsDisabled: boolean;
  Notifications: FundraiserNotification[];
}

export enum FundraiserState {
  Active = 'Active',
  Upcoming = 'Upcoming',
  ActiveUpcoming = 'ActiveUpcoming',
  Completed = 'Completed',
  Canceled = 'Canceled'
}

export enum FundraiserRelationship {
  SELLER = 'Seller',
  CHAIRPERSON = 'Chairperson',
  SUPERADMIN = 'SuperAdmin',
  SALESAGENT = 'SalesAgent',
  ISC = 'ISC'
}

export enum FundraiserType {
  Digital = 'DG',
  SimpleSignUp = 'SS',
  MealDeal = 'MD',
  Brochure = 'BR'
}

export enum PhoneNumberTypes {
  Mobile = 'Mobile',
  Work = 'Work',
  School = 'School'
}

export enum FundraiserStatus {
  Active = 0,
  Pending = 1,
  Completed = 2,
  Canceled = 3,
}

export enum FinalOrderStatus {
  UnknownOrderStatus = 0,
  FinalOrderDue = 1,
  FinalOrderPlaced = 2,
  FinalOrderComplete = 3,
  FinalOrderOverdue = 4,
  FinalOrderUpcoming = 5
}

export enum BrochureFormSteps {
  FundraiserDetails = 'fundraiserDetails',
  ChairpersonDetails = 'chairpersonDetails',
  DeliveryDetails = 'deliveryDetails',
  AdditionalDetails = 'additionalDetails',
}

export enum FundraiserFormStepState {
  Active = 'active',
  Preview = 'preview',
  Hidden = 'hidden',
}

export const ELEVATED_RELATIONSHIPS: readonly string[] = [
  FundraiserRelationship.CHAIRPERSON,
  FundraiserRelationship.SUPERADMIN,
  FundraiserRelationship.SALESAGENT,
  FundraiserRelationship.ISC,
];

export interface FundraiserProfitCheckAddress {
  fundraiserId: number;
  profitCheckPayableTo: string;
  groupAddress: string;
  groupCity: string;
  groupState: string;
  groupZipCode: string;
}

export type FundraiserOrderSummary = {
  deliveryStartDate: Date;
  deliveryEndDate: Date;
  kitTotal: number;
  kitOrders: KitOrders[];
}

export type FundraiserFinalOrder = {
  fundraiserId: number;
  kitOrders: KitOrders[];
}

type KitOrders = {
  code: string;
  color: string;
  id: number;
  name: string;
  price: number;
  quantity: number;
}

export type ExistingCoupon = {
  Id: number;
  Code: string;
  Applied: boolean;
}

export type CouponsAppliedToFinalOrder = {
  fundraiserId: number;
  appliedCoupons: ExistingCoupon[];
}
