import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import numeral from 'numeral';

export interface ISellerProgressProps {
  goal: number;
  actual: number;
}

const SellerProgress: React.FC<ISellerProgressProps> = ({ goal, actual }) => {
  const [ t ] = useTranslation();
  const [ percentCompleted, setPercentCompleted ] = useState<number>(actual / goal * 100);

  useEffect(() => {
    if (actual >= goal) {
      setPercentCompleted(100);
    } else {
      setPercentCompleted(actual / goal * 100);
    }
  }, [ actual, goal ]);

  return (
    <Flex sx={ { flexDirection: 'column', width: '100%' } }>
      <Flex sx={ { alignItems: 'baseline', gap: '4px' } }>
        <Text variant="stats.progressBar.value">
          {t('dashboard.header.actualRaised', { actual: numeral(actual).format('0,0') })}
        </Text>
        <Text variant="stats.progressBar.label">
          {t('dashboard.header.goalRaised', { goal: numeral(goal).format('0,0') })}
        </Text>
      </Flex>
      <Box
        aria-valuemax={ goal }
        aria-valuemin={ 0 }
        aria-valuenow={ actual }
        role="progressbar"
        sx={ {
          bg: 'white',
          outline: '1px solid',
          outlineColor: 'primaryGrayLight',
          borderRadius: '14px',
          height: '7px',
          position: 'relative',
          width: '100%',
        } }
      >
        <Box
          sx={ {
            bg: 'primaryOrange',
            outline: '1px solid',
            outlineColor: 'primaryGrayLight',
            borderRadius: '14px',
            height: '7px',
            width: `${ percentCompleted }%`,
          } }
        />
      </Box>
    </Flex>
  );
};

export default SellerProgress;
